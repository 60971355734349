import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

const FooterWrapper = styled.footer`
  ${props => (props.removeFooter ? `display: none;` : ` display: flex;`)}
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: 'Raleway', Helvetica, sans-serif;
  font-size: 0.5rem;
  color: #aaa;
  letter-spacing: 2px;
  text-transform: uppercase;

  a {
    color: #646464;
    margin: 0 5px;
    border-bottom: dotted 1px rgba(160, 160, 160, 0.65);
  }

  div:first-of-type {
    margin-bottom: 1rem;
  }
`
const OfferFooter = styled.footer`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: 'Source Sans Pro', Helvetica, sans-serif;
  font-size: 0.9rem;
  color: #646464;
  letter-spacing: 0.04rem;
  line-height: 0.7rem;
  a {
    color: #646464;
    margin: 0 5px;
    border-bottom: dotted 1px rgba(160, 160, 160, 0.65);
  }

  div:first-of-type {
    margin-bottom: 1rem;
  }
`

const offerFooter = () => {
  return (
    <OfferFooter>
      <p>© 2019 WealthStick.com. All Rights Reserved</p>
      <p>
        WealthStick.com is a display marketing platform, and neither a lender nor
        featured advertiser.
      </p>
      <p>
        Review all documents before signing. Please direct any questions
        directly to lenders or the site in which you applied.
      </p>
      <div>
        <Link to="/info/terms-of-use">Terms of Use</Link>|
        <Link to="/info/privacy-policy">Privacy Policy</Link>
      </div>
    </OfferFooter>
  )
}

const defFooter = removeFooter => {
  return (
    <FooterWrapper removeFooter={removeFooter}>
      <div>
        <Link to="/info/terms-of-use">Terms of Use</Link>|
        <Link to="/info/privacy-policy">Privacy Policy</Link> |
        <Link to="/contact">Contact</Link>
      </div>
      <div>© WealthStick.com</div>
    </FooterWrapper>
  )
}

const Footer = props => {
  const { removeFooter, offerPage } = props
  return offerPage ? offerFooter() : defFooter(removeFooter)
}

export default Footer
