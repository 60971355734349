import React from 'react'
import { StaticQuery, Link, graphql } from 'gatsby'
import { lowerCase, sortBy } from 'lodash'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Img from 'gatsby-image'
import { FaBars } from 'react-icons/fa'

import { NavWrapper, NavTitle, NavLinks } from '../styles'

const FLAG_QUERY = graphql`
  query flagQuery {
    allFile(filter: { relativeDirectory: { eq: "country-flags" } }) {
      edges {
        node {
          childImageSharp {
            resize(width: 30, height: 20, cropFocus: CENTER) {
              src
            }
          }
          name
        }
      }
    }
  }
`
const ToggleMenu = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.5rem;
  border-left: solid 1px rgba(160, 160, 160, 0.3);
  a {
    color: #aaaaaa;
    font-size: 1.1rem;
    svg {
      transition: color 0.2s ease, border-bottom-color 0.2s ease;
    }
  }
  margin-left: auto;
`

const Navbar = props => {
  return (
    <NavWrapper role="navigation" aria-label="main-navigation">
      <NavTitle>
        <Link to="/" title="Logo">
          WealthStick.com
        </Link>
      </NavTitle>
      <div css={ToggleMenu}>
        <a onClick={() => props.toggleMenu()}>
          <FaBars />
        </a>
      </div>
    </NavWrapper>
  )
}

export default Navbar
